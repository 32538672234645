import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { EagleUser, User } from '../../models/User';
import { Avatar, EAvatarPlacement } from '../../molecules/Avatar/Avatar';
import { ProfileChangePass } from '../../molecules/ProfileChangePass/ProfileChangePass';
import { ProfileLocation } from '../../molecules/ProfileLocation/ProfileLocation';
import { ProfileName } from '../../molecules/ProfileName/ProfileName';
import { AvatarsModal } from '../AvatarsModal/AvatarsModal';
import styles from './ProfilePerson.css';

type ProfilePersonType = {
  user: User | EagleUser;
  hasPassword: boolean;
};
const ProfilePersonBase = React.memo(({
  user,
  t,
  hasPassword,
}: ProfilePersonType & WithTranslation) => {
  const [isAvatarsVisible, setIsAvatarsVisible] = useState<boolean>(false);
  const isEagle = 'uid' in user;
  const userCanChangePassword = (isEagle && hasPassword) || (user as User).authProvider === 'Arkadium';
  const toggleAvatarsModal = (isAvatarsVisibleValue: boolean) => {
    setIsAvatarsVisible(isAvatarsVisibleValue);
  };

  return (
    <Container>
      <Row>
        <AvatarBlock>
          <Avatar avatar={user.avatar} place={EAvatarPlacement.PROFILE_PERSON}/>
          <ChangeAvatarBtn
            data-element-description="profile avatar btn"
            onClick={() => {
              toggleAvatarsModal(true);
            }}
          >
            {t('CHANGE_PROFILE_IMG')}
          </ChangeAvatarBtn>
          {isAvatarsVisible && (
            <AvatarsModal
              closeModal={() => {
                toggleAvatarsModal(false);
              }}
            />
          )}
          {userCanChangePassword && <ProfileChangePass/>}
        </AvatarBlock>
        <InfoBlock>
          <ProfileName/>
          <ProfileLocation/>
        </InfoBlock>
      </Row>
    </Container>
  );
});

ProfilePersonBase.displayName = 'ProfilePerson';
export const ProfilePerson = withTranslation()(ProfilePersonBase);

const Container = (props: any) => <div className={styles.Container} {...props} />;
const Row = (props: any) => <div className={'row'} {...props} />;
const AvatarBlock = (props: any) => <div className={`col-lg-3 col-md-4 col-3 ${styles.AvatarBlock}`} {...props} />;
const ChangeAvatarBtn = (props: any) => <button className={styles.AvatarBtn} {...props} />;
const InfoBlock = (props: any) => <div className={`col-lg-9 col-md-8 col-9 ${styles.InfoBlock}`} {...props} />;
