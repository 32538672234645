import { isServer } from '../../utils';
import { ActionTypes, CustomCategories } from '../models/Analytics';
import { ArenaConfig } from '../models/ArenaConfig';
import { Game } from '../models/Game';
import { PwaManifest } from '../models/PwaManifest';
import { AnalyticsGeneral } from './Analytics/AnalyticsGeneral';
import { AssetService } from './AssetService';
import { DeviceDetector } from './DeviceDetector';

const PWA_SIZES = ['76', '120', '152', '167', '180', '192', '512'];
const DEFAULT_MANIFEST = {
    background_color: '#f9f9f9',
    theme_color: '#f9f9f9',
    display: 'standalone',
};

class PwaServiceBase {
    public activateServiceWorker(config: ArenaConfig) {
        if (isServer || typeof window.navigator === 'undefined') {
            return;
        }

        if ('serviceWorker' in navigator) {
            const _window: any = window;
            const serviceWorkerPath = `/service-worker.js`;

            navigator.serviceWorker.register(serviceWorkerPath).catch((registrationError) => {
                console.log('SW registration failed: ', registrationError);
            });

            _window.addEventListener('beforeinstallprompt', (ev) => {
                ev.preventDefault();

                _window.deferredPrompt = ev;

                _window.deferredPrompt.userChoice.then((choiceResult) => {
                    if (choiceResult.outcome === 'dismissed') {
                        if (DeviceDetector.isDesktop()) {
                            AnalyticsGeneral.pwa(ActionTypes.CANCEL, CustomCategories.DESKTOP_PWA);
                        }
                    } else {
                        if (DeviceDetector.isDesktop()) {
                            AnalyticsGeneral.pwa(ActionTypes.INSTALL, CustomCategories.DESKTOP_PWA);
                        }
                    }
                });
            });
            _window.addEventListener('appinstalled', (evt) => {
                if (DeviceDetector.isDesktop()) {
                    AnalyticsGeneral.pwa(ActionTypes.INSTALL, CustomCategories.DESKTOP_PWA);
                } else {
                    AnalyticsGeneral.pwa(
                        ActionTypes.ANDROID_INSTALL_IMPRESSION,
                        CustomCategories.ANDROID_BOOKMARK_INSTALL
                    );
                }
            });
        }
    }

    public isPwaStandalone(): boolean {
        if (isServer) {
            return;
        }

        return this.isAndroidPcStandalone() || this.isIosStandalone();
    }

    public isIosStandalone() {
        if (typeof (window as any).navigator !== 'undefined') {
            if ((window as any).navigator.standalone === true) {
                return true;
            }
        }

        return false;
    }

    public isAndroidPcStandalone() {
        if (typeof window.matchMedia !== 'undefined') {
            if (window.matchMedia('(display-mode: standalone)').matches) {
                return true;
            }
        }

        return false;
    }

    public createManifest(
        lang: string,
        arenaInfo?: { name: string; description: string; iconName: string },
        game?: Game
    ) {
        if (isServer) {
            return;
        }

        this.createStartupImagesIos();

        this.clearLinks('apple-touch-icon');

        const icons = PWA_SIZES.map((size) => {
            const iconSrc = game
                ? AssetService.getGamePwaIcon(game, size)
                : AssetService.getArenaPwaIcon(arenaInfo.iconName, size);

            if (size === '180') {
                // default apple touch icon
                this.createLink(iconSrc, 'apple-touch-icon');
            }

            this.createLink(iconSrc, 'apple-touch-icon', `${size}x${size}`);

            return {
                src: iconSrc,
                sizes: `${size}x${size}`,
                type: 'image/png',
            };
        });
        let name = game ? game.name : arenaInfo.name;

        name = name[0].toUpperCase() + name.slice(1); // Capitalizing name
        const description = game ? game.description : arenaInfo.description;
        const manifest: PwaManifest = {
            name,
            description,
            icons,
            lang,
            short_name: name,
            start_url: window.location.href,
            ...DEFAULT_MANIFEST,
        };
        const stringManifest = JSON.stringify(manifest);
        const blob = new Blob([stringManifest], { type: 'application/json' });
        const manifestURL = URL.createObjectURL(blob);

        this.clearLinks('manifest');

        this.createLink(manifestURL, 'manifest');

        this.createMeta('theme-color', DEFAULT_MANIFEST.theme_color);
    }

    private clearLinks(linkRel: string) {
        const linksToClear = document.querySelectorAll(`[rel="${linkRel}"]`);

        if (linksToClear.length) {
            linksToClear.forEach((linkItem) => {
                try {
                    linkItem.remove();
                } catch (e) {
                    console.log('can not remove element');
                }
            });
        }
    }

    private createStartupImagesIos() {
        this.createLink(AssetService.getArenaPwaSplash('splash_512'), 'apple-touch-startup-image');
        this.createLink(
            AssetService.getArenaPwaSplash('apple_launch_640x1136'),
            'apple-touch-startup-image',
            null,
            '(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)'
        );
        this.createLink(
            AssetService.getArenaPwaSplash('apple_launch_750x1334'),
            'apple-touch-startup-image',
            null,
            '(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)'
        );
        this.createLink(
            AssetService.getArenaPwaSplash('apple_launch_1242x2688'),
            'apple-touch-startup-image',
            null,
            '(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)'
        );
        this.createLink(
            AssetService.getArenaPwaSplash('apple_launch_828x1792'),
            'apple-touch-startup-image',
            null,
            '(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)'
        );
        this.createLink(
            AssetService.getArenaPwaSplash('apple_launch_1125x2436'),
            'apple-touch-startup-image',
            null,
            '(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)'
        );
        this.createLink(
            AssetService.getArenaPwaSplash('apple_launch_1242x2208'),
            'apple-touch-startup-image',
            null,
            '(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3)'
        );
        this.createLink(
            AssetService.getArenaPwaSplash('apple_launch_2048x2732'),
            'apple-touch-startup-image',
            null,
            '(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)'
        );
        this.createLink(
            AssetService.getArenaPwaSplash('apple_launch_1668x2388'),
            'apple-touch-startup-image',
            null,
            '(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2)'
        );
        this.createLink(
            AssetService.getArenaPwaSplash('apple_launch_1668x2224'),
            'apple-touch-startup-image',
            null,
            '(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2)'
        );
        this.createLink(
            AssetService.getArenaPwaSplash('apple_launch_1536x2048'),
            'apple-touch-startup-image',
            null,
            '(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2)'
        );
        this.createLink(
            AssetService.getArenaPwaSplash('apple_launch_2048x1496'),
            'apple-touch-startup-image',
            '2048x1496',
            'screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) and (-webkit-min-device-pixel-ratio: 2)'
        );
        this.createLink(
            AssetService.getArenaPwaSplash('apple_launch_1536x2008'),
            'apple-touch-startup-image',
            '1536x2008',
            '(device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2)'
        );
        this.createLink(
            AssetService.getArenaPwaSplash('apple_launch_1242x2208'),
            'apple-touch-startup-image',
            '1242x2208',
            '(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3)'
        );
        this.createLink(
            AssetService.getArenaPwaSplash('apple_launch_414x736'),
            'apple-touch-startup-image',
            '414x736',
            '(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3)'
        );
    }

    private createLink(href: any, rel: string, sizes?: string, media?: string) {
        const doc = window.document;
        const link = doc.createElement('link');

        if (sizes) {
            link.setAttribute('sizes', sizes);
        }

        if (media) {
            link.setAttribute('media', media);
        }

        link.setAttribute('rel', rel);
        link.setAttribute('href', href);

        const linkExist = doc.querySelector(`[href="${href}"]`);

        if (!linkExist) {
            doc.querySelector('head').appendChild(link);
        }
    }

    private createMeta(name, content) {
        const doc = window.document;
        const meta = doc.createElement('meta');

        meta.setAttribute('name', name);
        meta.setAttribute('content', content);

        const metaExist = doc.querySelector(`[name="${name}"]`);

        if (!metaExist) {
            doc.querySelector('head').appendChild(meta);
        }
    }
}

export const PwaService = new PwaServiceBase();
