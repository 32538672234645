import { environment } from '../config/environment';
import { Game } from '../models/Game';
import { SlotParamsService } from './SlotAppParamsService';

// SSR origin URL, not current Arena URL origin
const origin = SlotParamsService.params.ssrOrigin;
const SHARED_BLOB = `${environment.X_INDEX_BLOB}/_arena-shared-content_`;

export class AssetService {
    static getAvatar(avatar: string) {
        return `${origin}/avatars/${avatar}`;
    }

    static getFlag(country: string) {
        country = country ? country.toLowerCase() : 'us';

        return `${origin}/flags/${country}.svg`;
    }

    static getArenaPwaIcon(name: string, size: string) {
        return `${origin}/pwa/icons/${name}_${size}.png`;
    }

    static getArenaPwaSplash(name: string) {
        return `${origin}/pwa/splash/${name}.png`;
    }

    static getGamePwaIcon(game: Game, size: string) {
        return `${game.assetOriginUrl}${game[`squareIcon${size}`]}`;
    }

    static shared(path = '') {
        return SHARED_BLOB + path;
    }

    static sharedRelativeToOrigin(absoluteOrigin: string, path = '') {
        return absoluteOrigin + '/arenaxstorage-blob/arenax-index/_arena-shared-content_' + path;
    }
}
