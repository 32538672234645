import classNames from 'classnames';
import React from 'react';
import { ImageService } from '../../services/ImageService';
import styles from './ProgressiveImage.css';

export enum ImageTypes {
    SQUARE = 'square', // thumb2x
    WIDE = 'wide', // secondary-thumb2x
    PROMO = 'promo', // promo-thumb
}

type ImgProps = {
    vertical?: boolean;
    [key: string]: any;
};

type ProgressiveImageProps = {
    imageType: ImageTypes;
    title?: string;
    alt?: string;
    useParentSize?: boolean;
    img?: string;
    webp?: string;
    imgBackFill?: string;
    webpBackFill?: string;
    className?: string;
    vertical?: boolean;
    emitRef?: (ref: HTMLDivElement) => void;
    customEventGameThumb?: string;
    customEventGameThumbWebp?: string;
};

type ProgressiveImageState = {
    color: string;
    image?: Element;
    loading: boolean;
    imgSrc?: string;
};

// Use predictable way to select color
function getColor(title: string): string {
    const index = title.length % ProgressiveImage.COLORS.length;

    return ProgressiveImage.COLORS[index];
}

export class ProgressiveImage extends React.PureComponent<ProgressiveImageProps, ProgressiveImageState> {
    static COLORS = [
        '#ef6c57',
        '#7ed3b2',
        '#b9e6d3',
        '#cd4545',
        '#ebf0c2',
        '#cde8f6',
        '#34a7b2',
        '#d195f9',
        '#e4406f',
        '#8fbbaf',
        '#6b7b8e',
        '#6b7b8e',
        '#fff0bc',
        '#db996c',
    ];

    static displayName = 'ProgressiveImage';

    observer: IntersectionObserver;

    containerRef = React.createRef<HTMLDivElement>();
    loadingImage: any;

    state = { color: null, image: null, loading: true, imgSrc: '' };

    constructor(props) {
        super(props);
        this.state.color = getColor(props.title || props.alt || '');
    }

    componentDidMount() {
        this.initLoad();
    }

    componentDidUpdate(prevProps: ProgressiveImageProps) {
        if (
            prevProps.img !== this.props.img
            || prevProps.webpBackFill !== this.props.webpBackFill
            || prevProps.imgBackFill !== this.props.imgBackFill
            || prevProps.customEventGameThumb !== this.props.customEventGameThumb
            || prevProps.customEventGameThumbWebp !== this.props.customEventGameThumbWebp
        ) {
            this.initLoad();
        }
    }

    componentWillUnmount() {
        if (this.loadingImage) {
            this.loadingImage.onload = null;
        }

        this.observer.disconnect();
    }

    onIntersect = (entries, observer: IntersectionObserver) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                this.fetchImage();
                observer.disconnect();
            }
        });
    }

    initLoad() {
        this.observer = new IntersectionObserver(this.onIntersect, { rootMargin: '100px' });
        this.observer.observe(this.containerRef.current);
        this.props.emitRef?.(this.containerRef.current);
    }

    fetchImage = () => {
        ImageService.isWebpSupported().then((webpSupport) => {
            const image = new Image();

            image.onload = () => {
                this.setState({ image: this.loadingImage.src, loading: false });
            };

            image.onerror = (e: Event) => {
                image.onerror = null;
                image.src = this.state.imgSrc =
                    webpSupport && this.props.webpBackFill
                        ? (this.props.customEventGameThumbWebp || this.props.webpBackFill)
                        : (this.props.customEventGameThumb || this.props.imgBackFill);
                this.loadingImage = image;
            };

            this.state.imgSrc = image.src = webpSupport && this.props.webp
                ? (this.props.customEventGameThumbWebp || this.props.webp)
                : (this.props.customEventGameThumb || this.props.img);
            this.loadingImage = image;
        });
    }

    render() {
        const { loading, color } = this.state;
        const { img, webp, imgBackFill, webpBackFill, title, alt, className, vertical, imageType } = this.props;
        let content = null;

        if (loading) {
            content = <ColorBlock key="loading" color={color} imageType={imageType} />;
        } else {
            content = (
                <Picture key="img-loaded" vertical={!!vertical}>
                    <source
                        srcSet={this.state.imgSrc}
                        type="image/webp"
                    />

                    <Img
                        vertical={vertical}
                        aria-label="Game Image"
                        role="img"
                        src={this.state.imgSrc}
                        title={title}
                        alt={alt || 'Game Image'}
                    />
                </Picture>
            );
        }

        return (
            <ProgressiveContainer key="img" ref={this.containerRef} className={className} imageType={imageType}>
                {content}
            </ProgressiveContainer>
        );
    }
}

const Img = ({ vertical, ...props }: ImgProps) => (
    <img
        className={classNames(styles.progressiveImageBase, {
            [styles.vertical]: !!vertical,
        })}
        alt={props.alt}
        {...props}
    />
);
const ColorBlock = ({ imageType, color, ...props }: any) => (
    <div className={classNames(styles.colorBlock)} style={{ backgroundColor: color }} {...props} />
);
const Picture = ({ vertical, ...props }) => (
    <picture
        className={classNames(styles.picture, {
            [styles.vertical]: vertical,
        })}
        {...props}
    />
);
const ProgressiveContainer = React.forwardRef<any, any>(({ className, imageType, ...props }: any, ref) => (
    <div
        className={classNames(styles.progressiveContainer, className, {
            [styles.square]: imageType === ImageTypes.SQUARE,
            [styles.wide]: imageType === ImageTypes.WIDE,
            [styles.promo]: imageType === ImageTypes.PROMO,
        })}
        {...props}
        ref={ref}
    />
));
