import classnames from 'classnames';
import { noop } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ESignInUpButtonSize, ESignInUpButtonTheme, SignInUpButton } from '../../../atoms/Buttons/SignInUpButton';
import { AuthType, EagleLoginService } from '../../../services/EagleLoginService';
import { ELoginSteps } from '../../../store/ducks/modal';
import { setShowRecaptcha } from '../../../store/ducks/recaptcha';
import { RECAPTCHA_ACTIONS, RECAPTCHA_ACTIONS_TYPE, RECAPTCHA_MODES } from '../../ChallengeCaptcha/hooks/useCaptcha';
import commonStyles from '../LoginPopup.css';
import { CallToActionText, PopupDescription, PopupTitle } from '../PopupDumbComponents/PopupDumbComponents';
import styles from './AfterSignForm.css';

type TProps = {
  openLoginForm: () => void;
  goToPreviousStep: () => void;
  email: string;
  tWithPrefix: WithTranslation['t'];
  onSupportClick: () => void;
  isThankYouForm: boolean;
  isResendEmailForm: boolean;
  prevStep: ELoginSteps;
  getCaptchaToken: (action: RECAPTCHA_ACTIONS_TYPE) => void;
  arenaDomainURL: URL;
  authType: AuthType;
  arenaLocale: string;
  captchaToken: string;
  showChallengeRecaptcha: boolean;
  clearCaptchaData: () => void;
};
// since we don't want to spam and overload our backend
const INITIAL_RESEND_TIMER = 10; // 10 seconds
const AfterSignForm = React.memo(
  ({
    openLoginForm,
    email,
    tWithPrefix,
    onSupportClick,
    isThankYouForm,
    goToPreviousStep,
    prevStep,
    isResendEmailForm,
    arenaDomainURL,
    getCaptchaToken,
    authType,
    arenaLocale,
    captchaToken,
    showChallengeRecaptcha,
    clearCaptchaData,
  }: TProps) => {
    const dispatch = useDispatch();
    const [isResendClicked, setIsResendClicked] = useState<boolean>(false);
    const [timeLeft, setTimeLeft] = useState<number>(INITIAL_RESEND_TIMER);
    const intervalRef = useRef<NodeJS.Timeout>(); // Add a ref to store the interval id
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // Using for HSN SSO email confirmation flow
    const emailFromHsnLogin = useSelector(({ modal }) => modal.login.email);
    const sso = useSelector(({ config }) => config.sso);
    const isHsnSso = sso.name === 'hsn';

    useEffect(() => {
      intervalRef.current = setInterval(() => {
        setTimeLeft((t) => t - 1);
      }, 1000);

      if (timeLeft <= 0) {
        clearInterval(intervalRef.current);
      }

      return () => clearInterval(intervalRef.current);
    }, [timeLeft]);

    useEffect(() => {
      if (isThankYouForm) {
        void fetchResetPassword(captchaToken, isLoading);
      } else {
        void fetchResendConfirmation(captchaToken, isLoading);
      }
    }, [captchaToken, isLoading]);

    const fetchResetPassword = async (token: string, loading: boolean) => {
      if (token && loading) {
        try {
          await EagleLoginService.requestResetPassword({
            email,
            resetPasswordConfirmationFormUrl: arenaDomainURL,
            registrationPlaceUrl: arenaDomainURL,
            captchaToken: token,
            captchaMode: showChallengeRecaptcha ? RECAPTCHA_MODES.CHALLENGE : undefined,
          });
          handleSuccess();
        } catch (err) {
          handleError(err);
        }
      }
    };
    const fetchResendConfirmation = async (token: string, loading: boolean) => {
      if (token && loading) {
        try {
          await EagleLoginService.resendConfirmation({
            email: isHsnSso ? emailFromHsnLogin : email,
            authType: isHsnSso ? AuthType.HSN : authType,
            languageId: arenaLocale.toUpperCase(),
            captchaToken,
            captchaMode: showChallengeRecaptcha ? RECAPTCHA_MODES.CHALLENGE : undefined,
            resendConfirmationFormUrl: arenaDomainURL.origin,
          });
          handleSuccess();
        } catch (err) {
          handleError(err);
        }
      }
    };
    const handleSuccess = () => {
      setIsLoading(false);
      clearCaptchaData();
      setTimeLeft(INITIAL_RESEND_TIMER);
    };
    const handleError = (err: number) => {
      if (err === 1023) {
        dispatch(setShowRecaptcha(true));
      } else {
        setIsLoading(false);
        clearCaptchaData();
        setIsResendClicked(true);
        setTimeLeft(INITIAL_RESEND_TIMER);
      }
    };
    const handleSubmitClick = () => {
      setIsLoading(true);

      if (isThankYouForm) {
        getCaptchaToken(RECAPTCHA_ACTIONS.PASSWORD_RESET);
      } else {
        getCaptchaToken(RECAPTCHA_ACTIONS.CONFIRMATION_RESEND);
      }
    };
    const title = isThankYouForm ? (
      tWithPrefix('THANK_YOU_TITLE')
    ) : (
      <>
        <p>{tWithPrefix('ALMOST_DONE_TITLE_START')}</p>
        <p>{tWithPrefix('ALMOST_DONE_TITLE_END')}</p>
      </>
    );
    const suffixForText = prevStep === ELoginSteps.FORGOT_PASSWORD ? '_RESET' : '_CONFIRMATION';
    const DO_NOT_RECEIVE_EMAIL_TEXT = `ALMOST_DONE_IF_YOU_DO_NOT_RECEIVE_EMAIL${suffixForText}`;
    const EMAIL_HAS_BEEN_SENT_TEXT = `ALMOST_DONE_EMAIL_HAS_BEEN_SENT_AGAIN${suffixForText}`;

    return (
      <>
        {!showChallengeRecaptcha && (
          <div className={styles.afterSignContentWrapper}>
            <PopupTitle isEagle text={title}/>
            <CallToActionText
              defaultOrder
              isEagle
              actionText={tWithPrefix(
                isThankYouForm ? 'THANK_YOU_WE_SENT_AN_EMAIL_TO' : 'ALMOST_DONE_DESCRIPTION',
              )}
              buttonText={email}
              onClick={isHsnSso ? noop : openLoginForm}
              noMargin
              defaultCursor={isHsnSso}
            />
            {isThankYouForm && <PopupDescription isEagle text={tWithPrefix('THANK_YOU_WITH_INSTRUCTIONS')}/>}
            <img
              className={classnames(commonStyles.mt14, commonStyles.mb10)}
              src={'/images/after-sign-image.png'}
              alt={`${tWithPrefix('ALMOST_DONE_TITLE_START')} ${tWithPrefix('ALMOST_DONE_TITLE_END')}`}
            />
            <PopupDescription
              isEagle
              text={tWithPrefix(isResendClicked ? EMAIL_HAS_BEEN_SENT_TEXT : DO_NOT_RECEIVE_EMAIL_TEXT)}
            />
            <SignInUpButton
              onClick={handleSubmitClick}
              theme={ESignInUpButtonTheme.EAGLE_GREEN}
              size={ESignInUpButtonSize.SMALL}
              isLoading={isLoading}
              isEagle
              text={timeLeft ? String(timeLeft) : tWithPrefix('ALMOST_DONE_RESEND')}
              withoutIcon
              disabled={Boolean(timeLeft)}
            />
            <CallToActionText
              wrapperClassName={classnames(commonStyles.mt14, commonStyles.mb10)}
              actionText={tWithPrefix('REQUEST_EMAIL_STILL_NEED_HELP')}
              buttonText={tWithPrefix('REQUEST_EMAIL_SUPPORT')}
              isEagle
              onClick={onSupportClick}
              defaultOrder
            />
            {prevStep && !isResendEmailForm && (
              <>
                <PopupDescription
                  isEagle
                  text={tWithPrefix('ALMOST_DONE_FOUND_TYPO')}
                  className={commonStyles.m0}
                />
                <CallToActionText
                  wrapperClassName={commonStyles.m0}
                  actionText={tWithPrefix('ALMOST_DONE_TO_SEND_IT')}
                  buttonText={tWithPrefix('ALMOST_DONE_CLICK_HERE')}
                  isEagle
                  onClick={goToPreviousStep}
                  buttonFirst
                />
              </>
            )}
          </div>
        )}
      </>
    );
  },
);

AfterSignForm.displayName = 'AfterSignForm';
export { AfterSignForm };
