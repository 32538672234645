interface EnvironmentVariables {
    // Replacement at build-time
    BUILD_ID;
    ENV: 'live' | 'canary' | 'dev';
    //
    BASE_CDN;
    BASE_CDN_OVERWRITES;
    GAME_RENDERING_IFRAME;
    // ads lib
    DISPLAY_ADS;
    VIDEO_ADS;
    // Headers Blob
    ARENA_HEADERS_BLOB;
    // Services
    USER_API;
    SCORE_API;
    UUP_SCORE_API;
    EAGLE_USER_API;
    EAGLE_LEVELUP_API;
    EAGLE_PAYMENT_API;
    EAGLE_VIRTUAL_ITEMS_API;
    EAGLE_USER_GAME_DATA_API;
    //Recurly
    RECURLY_BILLING_PAGE;
    RECURLY_PUBLIC_KEY;
    RECURLY_PLAN_PREFIX;
    RECURLY_GIFT_CARD_PRODUCT_CODE;
    // ArenaConnect
    FB_BUTTON_PATH;
    GOOGLE_BUTTON_PATH;
    DEFAULT_GOOGLE_APP_ID;
    LOGIN_POPUP;
    // Blobs
    FEED_API;
    GAMES_BLOB;
    GAMES_THUMBS_BLOB;
    X_INDEX_BLOB;
    HELP_CONTENT_BLOB;
    ANALYTICS_SORTING_BLOB;
    RELATED_GAMES_BLOB;
    APP_INSIGHTS_SSR_KEY;
    DEFAULT_ADS_TXT_PATH;
    GLOBAL_ROBOTS_TXT_PATH;
    REDIRECTS_SETTINGS_PATH;
    IFRAME_GAMES_LIST_PATH;
    GRM_GAMES_LIST_PATH;
    EVENT_CATEGORY_DATA_PATH;
    OPENWEB_TOKENS_PATH;
    LIGHTBOX_PROMOTIONS_PATH;
    GAMES_WITH_EAGLE_LIST_PATH;
    GEMS_TEST_SKU: string;
    SKU_GEMS_SKIP_AD: string;
    SKU_GEMS_SKIP_AD_BUNDLE: string;
    ERROR_DETAILS: boolean;

  RECAPTCHAAPPKEY: string;
  CDN_CAPTCHA_IFRAME: string;
  CHALLENGE_CAPTCHA_KEY: string;
  GAME_API;
}

let env: EnvironmentVariables;

if (BUILD_ENV === 'prod') {
  env = require('./environment.prod').environment;
} else if (BUILD_ENV === 'canary') {
  env = require('./environment.canary').environment;
} else {
  env = require('./environment.dev').environment;
}

console.debug('BUILD: Used environment -> ' + BUILD_ENV);
env.BUILD_ID = BUILD_ID;

// @ts-ignore
export const environment: EnvironmentVariables = env;
