import classNames from 'classnames';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { formatNumbers } from '../../../utils';
import styles from './GameEndLevelUp.css';

type GameEndLevelUpProps = {
    level: number;
};

class GameEndLevelUpBase extends React.PureComponent<GameEndLevelUpProps & WithTranslation> {
    state = {
        isVisible: true,
        isAnimationStarted: false,
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({ isAnimationStarted: true });
        }, 500);

        setTimeout(() => {
            this.setState({ isVisible: false });
        }, 6000);
    }

    render() {
        const { level, t } = this.props;

        return (
            <React.Fragment>
                {this.state.isVisible && (
                    <LevelUp data-element-description="levelup popup">
                        <Content isAnimationStarted={this.state.isAnimationStarted}>
                            <Title data-element-description="levelup popup title">{t('LEVEL_UP')}</Title>
                            <TextWrap>
                                <Image />
                                <Text>{formatNumbers(level)}</Text>
                            </TextWrap>
                        </Content>
                    </LevelUp>
                )}
            </React.Fragment>
        );
    }
}

const LevelUp = (props) => <div className={styles.levelUp} {...props} />;
const Content = ({ isAnimationStarted, ...props }) => (
    <div
        className={classNames(styles.content, {
            [styles.isAnimationStarted]: isAnimationStarted,
        })}
        {...props}
    />
);
const Title = (props) => (
    <h2 className={styles.title} {...props}>
        {props.children}
    </h2>
);
const TextWrap = (props) => <div className={styles.textWrap} {...props} />;
const Text = (props) => <span className={styles.text} {...props} />;
const Image = (props) => <img src={'images/levelup_star.png'} className={styles.image} alt="level up" {...props} />;

export const GameEndLevelUp = withTranslation()(GameEndLevelUpBase);
