import classNames from 'classnames';
import React, { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DeviceDetector } from '../../services/DeviceDetector';
import { ArrowStickWhiteIcon } from '../Icons/ArrowStickWhite';
import styles from './ShopDescription.css';

const ShopDescriptionBase = (props: WithTranslation) => {
    const isCollapsible = DeviceDetector.isMobile();
    const [isOpen, setIsOpen] = useState(isCollapsible ? false : true);
    const onClick = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={styles.wrapper}>
            {!isCollapsible && (
                <img className={styles.gemsImage} src={'/images/GemsShopDescription.png'} alt="Gems Shop Description" />
            )}
            <article>
                <button className={styles.descriptionTitle} onClick={isCollapsible ? onClick : null}>
                    <span>{props.t('GEMS.DESCRIPTION_TITLE')}</span>
                    {isCollapsible && (
                        <ArrowStickWhiteIcon className={classNames(styles.arrowIcon, { [styles.opened]: isOpen })} />
                    )}
                </button>
                {isOpen ? <p className={styles.descriptionText}>{props.t('GEMS.DESCRIPTION_TEXT')}</p> : null}
            </article>
        </div>
    );
};

export const ShopDescription = withTranslation()(ShopDescriptionBase);
