import { GRM } from '../../modules/GameRenderingModule';

export const environment = {
    ENV: 'live',
    BASE_CDN: 'https://arenacloud.cdn.arkadiumhosted.com',
    BASE_CDN_OVERWRITES: 'https://arenacloud.cdn.arkadiumhosted.com/overwrites-live',
    GAME_RENDERING_IFRAME:
        'https://arenacloud.cdn.arkadiumhosted.com/arkadiummodulesstorage-blob/game-rendering-iframe',

    GAMES_WITH_EAGLE_LIST_PATH:
        'https://arenacloud.cdn.arkadiumhosted.com/arkadiummodulesstorage-blob/game-rendering-games-list/games-with-eagle-list.json',
    // ads lib
    DISPLAY_ADS: 'https://ams.cdn.arkadiumhosted.com/advertisement/display/stable/display-ads.js',
    VIDEO_ADS: 'https://ams.cdn.arkadiumhosted.com/advertisement/video/stable/video-ads.js',
    // Headers root dir
    ARENA_HEADERS_BLOB: 'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-headers-prod/app/clients',
    // Services
    USER_API: 'https://arenacloud.cdn.arkadiumhosted.com/arenax-51-api-user-live/api/v1',
    SCORE_API: 'https://arenacloud.cdn.arkadiumhosted.com/arenax-51-api-score-live/api/v1',
    UUP_SCORE_API: 'https://arenacloud.cdn.arkadiumhosted.com/uup-api-score-prod/api/v1',
    EAGLE_USER_API: 'https://eagle-user-api-prod.arkadiumhosted.com/',
    EAGLE_LEVELUP_API: 'https://arenacloud.cdn.arkadiumhosted.com/eagle-levelup-api/api/v1',
    EAGLE_PAYMENT_API: 'https://arenacloud.cdn.arkadiumhosted.com/eagle-payment-api-prod/',
    EAGLE_VIRTUAL_ITEMS_API: 'https://arenacloud.cdn.arkadiumhosted.com/eagle-virtual-item-api-prod/',
    EAGLE_USER_GAME_DATA_API: GRM.CONSTS.EAGLE_USER_GAME_DATA_API_ENDPOINT.prod,

  // Recurly
  RECURLY_BILLING_PAGE: `https://arkadium.recurly.com/account/`,
  RECURLY_PUBLIC_KEY: 'ewr1-6voS8MpDMGlmNWVWMrqyp7',
  RECURLY_PLAN_PREFIX: '',
  RECURLY_GIFT_CARD_PRODUCT_CODE: 'gift_card',

    // ArenaConnect
    FB_BUTTON_PATH: 'https://arenaxstorage.blob.core.windows.net/arenax-connect/azure.facebook.next.index.html',
    GOOGLE_BUTTON_PATH: 'https://arenaxstorage.blob.core.windows.net/arenax-connect/azure.google.next.index.html',
    DEFAULT_GOOGLE_APP_ID: '357011364658-46j1mfak3u1tl958cmhn9b5n9g5jfvpm.apps.googleusercontent.com',
    LOGIN_POPUP:
        'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-connect/51-azure.login-popup.html',
    // Blobs
    FEED_API: 'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-game-feeds-content',
    GAMES_BLOB: 'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-games',
    GAMES_THUMBS_BLOB:
        'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-index/_arena-shared-content_/arkcom-game-arts',
    X_INDEX_BLOB: 'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-index',
    HELP_CONTENT_BLOB: 'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-help-pages',
    ANALYTICS_SORTING_BLOB:
        'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax51-games-sorting-analytics',
    RELATED_GAMES_BLOB: 'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax51-related-games-analytics',
    APP_INSIGHTS_SSR_KEY: '29bc13dc-c331-4e2b-ac37-abab579231e7', // arena51-appins-live
    DEFAULT_ADS_TXT_PATH: 'https://arkadsstorage.blob.core.windows.net/arena5/live/ads.txt',
    GLOBAL_ROBOTS_TXT_PATH: 'https://arenaxstorage.blob.core.windows.net/arenax-global-settings/robots/robots.txt',
    REDIRECTS_SETTINGS_PATH:
        'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-global-settings/redirects/redirects.json',
    OPENWEB_TOKENS_PATH:
        'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-global-settings/openweb/openweb-tokens.json',
    IFRAME_GAMES_LIST_PATH:
        'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-index/_arena-shared-content_/ark-iframe-games/iframe-games-list.json',
    GRM_GAMES_LIST_PATH: GRM.CONSTS.GRM_GAMES_LIST_URL.arena51.live,
    EVENT_CATEGORY_DATA_PATH:
        'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-index/_arena-shared-content_/ark-event-category/event-category-config.json',

  LIGHTBOX_PROMOTIONS_PATH:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-index/_arena-shared-content_/arena-lightbox-promotions/',
  // Skip ad product SKU name
  GEMS_TEST_SKU: 'gems_prod',
  SKU_GEMS_SKIP_AD: 'skip_ad',
  SKU_GEMS_SKIP_AD_BUNDLE: 'skip_ad_bundle',

  ERROR_DETAILS: false,

  // Recaptcha Key ID
  RECAPTCHAAPPKEY: '6LeKF_4jAAAAAAYevkgmxE64WG6ZagnLOH8FwA-g',
  CDN_CAPTCHA_IFRAME:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-3rd-party-scripts/captcha/iframe-captcha-production.html',
  CHALLENGE_CAPTCHA_KEY: '6LeIG7EoAAAAAHtfqlgeaP5u-QOLF9YzpTyj0zy7',
  GAME_API: 'https://arenacloud.cdn.arkadiumhosted.com/game-api-origin-live',
};
