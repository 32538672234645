import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import ArkadiumLogo from '../../../public/svg/ArkadiumLogo.svg';
import { CloseIcon } from '../../atoms/Icons/CloseIcon';
import { UrlService } from '../../services/UrlService';
import styles from './PoweredBy.css';

const FOR_PUBLISHERS_URL = 'https://corporate.arkadium.com/arena/';
const FOR_BRANDS_URL = 'https://corporate.arkadium.com/game-licensing/';
const ABOUT_URL = 'https://corporate.arkadium.com/about/';

interface PoweredByModalProps {
    show: boolean;
    closeModal: () => void;
    t;
}

class PoweredByModalBase extends React.PureComponent<PoweredByModalProps & WithTranslation> {
    dialogRef = React.createRef<any>();

    onBackdropClick = (event) => {
        if (this.props.show) {
            const dialogNode = this.dialogRef.current;

            if (dialogNode !== event.target && !dialogNode.contains(event.target)) {
                this.closePoweredByModal();
            }
        }
    };

    closePoweredByModal() {
        this.props.closeModal();
    }

    render() {
        if (!this.props.show) {
            return null;
        }

        const { t } = this.props;
        const privacyPolicyUrl = UrlService.getPrivacyPolicyUrl(this.props.i18n.language);

        return (
            <Backdrop onClick={this.onBackdropClick}>
                <Dialog ref={this.dialogRef}>
                    <ExitButton onClick={() => this.closePoweredByModal()}>
                        <CloseIcon />
                    </ExitButton>
                    <LeftPart>
                        <ArkadiumLogo />
                        <UnderLogoText>{t('POWERED_BY_UNDER_LOGO_TEXT')}</UnderLogoText>
                        <Paragraph
                            title={t('POWERED_BY.FOR_PUBLISHERS_TITLE')}
                            text={t('POWERED_BY_FOR_PUBLISHERS_TEXT')}
                            url={FOR_PUBLISHERS_URL}
                            linkText={t('POWERED_BY.LEARN_MORE')}
                        />
                        <Paragraph
                            title={t('POWERED_BY.FOR_BRANDS_TITLE')}
                            text={t('POWERED_BY_FOR_BRANDS_TEXT')}
                            url={FOR_BRANDS_URL}
                            linkText={t('POWERED_BY.LEARN_MORE')}
                        />
                        <BottomLinks>
                            <Link url={privacyPolicyUrl}>{t('POWERED_BY.OUR_PRIVACY_POLICY')}</Link>
                            <Link url={ABOUT_URL}>{t('POWERED_BY.ABOUT_ARKADIUM')}</Link>
                        </BottomLinks>
                    </LeftPart>
                    <RightPart>
                        <Image />
                    </RightPart>
                </Dialog>
            </Backdrop>
        );
    }
}

const Backdrop = (props) => <div className={styles.backdrop} {...props} />;
const Dialog = React.forwardRef<HTMLDivElement, any>((props, ref) => (
    <div ref={ref} className={styles.dialog} {...props} />
));
const LeftPart = (props) => <div className={styles.leftPart} {...props} />;
const RightPart = (props) => <div className={styles.rightPart} {...props} />;
const Paragraph = ({ title, text, url, linkText }) => {
    return (
        <TextContainer>
            <SubTitle>{title}</SubTitle>
            <Text>{text}</Text>
            <Link url={url}>{linkText}</Link>
        </TextContainer>
    );
};
const BottomLinks = (props) => <div className={styles.bottomLinks} {...props} />;
const UnderLogoText = (props) => <div className={styles.underLogo} {...props} />;
const ExitButton = (props) => <div className={styles.exitButton} {...props} />;
const Image = (props) => <img src={'images/promo-games.png'} alt="Promo games" className={styles.image} {...props} />;
const SubTitle = (props) => (
    <h3 className={styles.subTitle} {...props}>
        {props.children}
    </h3>
);
const TextContainer = (props) => <div className={styles.textContainer} {...props} />;
const Text = (props) => <span className={styles.text} {...props} />;
const Link = (props) => (
    <a className={styles.link} href={props.url} rel={'nofollow'} target={'_blank'} {...props}>
        {props.children}
    </a>
);

export const PoweredByModal = withTranslation()(PoweredByModalBase);
