import classNames from 'classnames';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { EyeCrossedIcon } from '../../atoms/Icons/EyeCrossedIcon';
import { Link } from '../../atoms/Link/Link';
import { ActionTypes } from '../../models/Analytics';
import { KeysEnum } from '../../models/Enums';
import { Game } from '../../models/Game';
import { AnalyticsGamePage } from '../../services/Analytics/AnalyticsGamePage';
import { DeviceDetector } from '../../services/DeviceDetector';
import { HelpSections } from '../../services/HelpContentService';
import { LocalStorageService } from '../../services/LocalStorage';
import styles from './AdBlocker.css';

type AdBlockerBaseProps = {
    game: Game;
    isForMobileCrawling: boolean;
};

class AdBlockerBase extends React.PureComponent<AdBlockerBaseProps & WithTranslation> {
    private static onInstructionsClick() {
        AnalyticsGamePage.adBlock(ActionTypes.CLICK);
    }

    componentDidMount() {
        LocalStorageService.setItem(KeysEnum.wasAdBlocker, true);
        AnalyticsGamePage.adBlock(ActionTypes.IMPRESSION);
    }

    render() {
        const { t, game, isForMobileCrawling } = this.props;

        return (
            <Container isForMobileCrawling={isForMobileCrawling}>
                <Header alt="Ad Blocker" />
                <Title>
                    <IconWrapper>
                        <EyeCrossedIcon />
                    </IconWrapper>
                    {t('AD_BLOCK_DETECT.HEADER')}
                </Title>
                <Text>{t('AD_BLOCK_DETECT.BODY')}</Text>
                <Instructions>
                    {t('AD_BLOCK_DETECT.FOOTER')}
                    <InstructionsLink
                        to={`/${t('ROUTES.HELP')}?section=${HelpSections.AD_BLOCK}&game=${game.alias}`}
                        onClick={AdBlockerBase.onInstructionsClick}
                    >
                        {t('AD_BLOCK_DETECT.MESSAGE_FOOTER_BOLD')}
                    </InstructionsLink>
                </Instructions>
            </Container>
        );
    }
}

const Container = (props: any) => (
    <div
        className={classNames(styles.container, {
            [styles.fullScreen]: DeviceDetector.isNotPc() && !props.isForMobileCrawling,
        })}
        {...props}
    />
);
const Header = (props: any) => (
    <img src="images/blocker-header.jpg" alt={props.alt} className={styles.header} {...props} />
);
const IconWrapper = (props: any) => <div className={styles.iconWrapper} {...props} />;
const Title = (props: any) => (
    <h2 className={styles.title} {...props}>
        {props.children}
    </h2>
);
const Text = (props: any) => <p className={styles.text} {...props} />;
const Instructions = (props: any) => <p className={styles.instructions} {...props} />;
const InstructionsLink = (props: any) => <Link className={styles.link} {...props} />;

export const AdBlocker = withTranslation()(AdBlockerBase);
