import numeral from 'numeral';
import { environment } from './client/config/environment';
import { HeightOneHundredBodyClassname, OverflowHiddenBodyClassname } from './client/models/Enums';
import { Game } from './client/models/Game';
import { LeaderboardRecord } from './client/models/LeaderboardRecord';
import DefaultTheme from './theme';

export const isServer = typeof window === 'undefined';

export function isMobileMode() {
  if (isServer) {
    return false;
  }

  return window.matchMedia(`(max-width: ${DefaultTheme.breakPoints.md - 1}px)`).matches;
}

export function isLandscape() {
  if (isServer) {
    return false;
  }

  return window.matchMedia('(orientation:landscape)').matches;
}

export function loadScript(url: string, parent = document): Promise<void> {
  return new Promise((resolve, reject) => {
    const script: any = document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;

    if (script.readyState) {
      // IE
      script.onreadystatechange = () => {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
          script.onreadystatechange = null;
          resolve();
        }
      };
    } else {
      // Others
      script.onload = resolve;
      script.onerror = reject;
    }

    script.src = url;
    parent.head.appendChild(script);
  });
}

export function hostnameToArenaDomain(url: string) {
  return url ? url.replace(/^www./, '') : '';
}

export function endsWith(str: string, substr: string) {
  return str.indexOf(substr, str.length - substr.length) !== -1;
}

export function getPercentFromNumbers(percentsFromNum: number, thresholdNum: number) {
  return Math.round(percentsFromNum / (thresholdNum / 100));
}

export const trimUndefinedProps = (obj: {}) => {
  return Object.keys(obj).forEach((key) => (obj[key] === undefined ? delete obj[key] : null));
};

export function debounce(func: any, timeout = 300) {
  let timer: ReturnType<typeof setTimeout>;

  return (...args: any) => {
    const next = () => func(...args);

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(next, timeout);
  };
}

export function formatNumbers(numbers: number): string {
  return numeral(numbers).format('0,0');
}

export function formatLBScores(numbers: number): string {
  const absoluteNum = Math.abs(Number(numbers));

  return isNumBillionOrMore(numbers)
    ? // 12 Zeroes or more for Billions
    numeral(absoluteNum / 1.0e12).format('0,0', Math.floor) + ' MM'
    : // 7 Zeroes o more for Millions
    isNumTenMillionsOrMore(numbers)
      ? numeral(absoluteNum / 1.0e6).format('0,0', Math.floor) + ' M'
      : numeral(absoluteNum).format('0,0', Math.floor);
}

export function isNumBillionOrMore(numbers: number): boolean {
  return Math.abs(Number(numbers)) >= 1.0e12;
}

export function isNumTenMillionsOrMore(numbers: number): boolean {
  return Math.abs(Number(numbers)) >= 1.0e7;
}

export function normalizePath(path: string): string {
  if (path.length > 1 && path.endsWith('/')) {
    return path.slice(0, path.length - 1);
  }

  return path;
}

export function sanitizeString(str: string, forUsername: boolean = false): string {
  if (!str) {
    return str;
  }

  if (forUsername && !str) {
    return str;
  }

  const maliciousSymbols = Object.assign(
    {},
    {
      // Like in arenax-api-user (public static string SanitizeUsername(string username) in UserModelConverterExtention.cs)
      '<': '',
      '>': '',
      '\'': '',
      '"': '',
    },
    !forUsername
      ? {}
      : {
        '\\(': '',
        '\\)': '',
        '&': '',
      },
  );

  for (const [malicious, safe] of Object.entries(maliciousSymbols)) {
    const regex = new RegExp(malicious, 'g');

    str = str.replace(regex, safe);
  }

  str = str.trim();

  return str;
}

export const isIE = () => {
  if (isServer) {
    return false;
  }

  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');

  return msie > -1 || !!navigator.userAgent.match(/Trident.*rv:11\./);
};

export const isSafari =
  !isServer &&
  navigator.vendor &&
  navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf('CriOS') === -1 &&
  navigator.userAgent.indexOf('FxiOS') === -1;

export function isUclickGame(game: Game): boolean {
  if (!game) {
    return false;
  }

  return game.slug.includes('uclick') || game.slug.includes('amu-');
}

export const getOriginUrlForIframe = (isGameRenderingModule: boolean) => {
  if (isGameRenderingModule) {
    return environment.GAME_RENDERING_IFRAME;
  }

  if (window.location.host === 'arena51-staging.arkadiumarena.com') {
    return environment.BASE_CDN_OVERWRITES.replace('live', 'staging');
  }

  return environment.BASE_CDN_OVERWRITES;
};

export const getOriginUrlForCaptchaIframe = () => {
  if (window.location.host === 'localhost:4200') {
    // for faster development to not rebuild project every time html has changed
    return 'http://localhost:4200/iframe-captcha.html';
  }

  return environment.CDN_CAPTCHA_IFRAME;
};

export type TEagleErrorRegistrationResponse = { email: string; password: string };
export type TErrorValidation<FieldName> = { field: FieldName; description: string };
export type TValidate<FieldName> = (
  str: string | boolean,
  str2?: string,
) => { isValid: boolean; errors: Array<TErrorValidation<FieldName>> };
export type TEmailField = 'email';
export type TPasswordField =
  'password'
  | 'confirm-password';
export type TNicknameField = 'nickname';
export type TGdprUserDataField = 'gdpr';

export const validateEmail: TValidate<TEmailField> = (email: string) => {
  const res = {
    isValid: true,
    errors: [],
  };

  if (!email) {
    return {
      isValid: false,
      errors: [
        {
          field: 'email',
          description: '101',
        },
      ],
    };
  }

  const emailSymbolsValidator =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!emailSymbolsValidator.test(String(email).toLowerCase())) {
    return {
      isValid: false,
      errors: [
        {
          field: 'email',
          description: '107',
        },
      ],
    };
  }

  const RECIPIENT_NAME_MAX_LENGTH = 64;
  const recipientName = email.split('@')[0];

  if (recipientName.length > RECIPIENT_NAME_MAX_LENGTH) {
    return {
      isValid: false,
      errors: [
        {
          field: 'email',
          description: '1071',
        },
      ],
    };
  }

  const isEmailLatin = isStringLatin(email);

  if (!isEmailLatin) {
    res.isValid = false;
    res.errors.push({
      field: 'email',
      description: '3000',
    });
  }

  const {
    isValid: isLengthValid,
    errors: lengthErrors,
  } = checkEmailLength(email);

  if (!isLengthValid) {
    res.isValid = false;
    res.errors.push(...lengthErrors);
  }

  return res;
};

export const validatePassword: TValidate<TPasswordField> = (password: string) => {
  if (!password) {
    return {
      isValid: false,
      errors: [
        {
          field: 'password',
          description: '109',
        },
        {
          field: 'confirm-password',
          description: '109',
        },
      ],
    };
  }

  if (password.length < 6) {
    return {
      isValid: false,
      errors: [
        {
          field: 'password',
          description: '103',
        },
        {
          field: 'confirm-password',
          description: '103',
        },
      ],
    };
  }

  return {
    isValid: true,
    errors: [],
  };
};

export const validatePasswords: TValidate<TPasswordField> = (password: string | boolean, confirmPassword: string) => {
  if (password !== confirmPassword) {
    return {
      isValid: false,
      errors: [
        {
          field: 'password',
          description: '108',
        },
      ],
    };
  }

  return validatePassword(password);
};

const isStringLatin = (str: string) => {
  const latinAndSpecSymbols = /^[a-zA-Z0-9+()*_@\-!#$%^&,."'\][]+$/;

  return latinAndSpecSymbols.test(str);
};

export const checkIfPassIsLatin: TValidate<TPasswordField> = (password: string) => {
  const res = {
    isValid: true,
    errors: [],
  };
  const isPassLatin = isStringLatin(password);

  if (!isPassLatin) {
    return {
      isValid: false,
      errors: [
        {
          field: 'password',
          description: '3001',
        },
      ],
    };
  }

  return res;
};

export const checkIfPassIsNotEmpty: TValidate<TPasswordField> = (password: string) => {
  const res = {
    isValid: true,
    errors: [],
  };

  if (!password) {
    return {
      isValid: false,
      errors: [
        {
          field: 'password',
          description: '109',
        },
      ],
    };
  }

  return res;
};

export const stringHas8CharactersLong: TValidate<TPasswordField> = (str: string) => {
  const res = {
    isValid: true,
    errors: [],
  };

  if (str.length < 8) {
    return {
      isValid: false,
      errors: [
        {
          field: 'password',
          description: '110',
        },
      ],
    };
  }

  return res;
};

export const stringHas100CharactersLong: TValidate<TPasswordField> = (str: string) => {
  const res = {
    isValid: true,
    errors: [],
  };

  if (str.length > 100) {
    return {
      isValid: false,
      errors: [
        {
          field: 'password',
          description: '3003',
        },
      ],
    };
  }

  return res;
};

export const checkEmailLength: TValidate<TEmailField> = (str: string) => {
  const res = {
    isValid: true,
    errors: [],
  };

  if (str.length > 255) {
    return {
      isValid: false,
      errors: [
        {
          field: 'email',
          description: '3002',
        },
      ],
    };
  }

  return res;
};

export const stringHas1Uppercase: TValidate<TPasswordField> = (str: string) => {
  const res = {
    isValid: true,
    errors: [],
  };

  if (!/(?=.*[A-Z])/.test(str)) {
    return {
      isValid: false,
      errors: [
        {
          field: 'password',
          description: '111',
        },
      ],
    };
  }

  return res;
};

export const validateEaglePassword: TValidate<TPasswordField> = (
  password: string,
  confirmPassword: string | undefined,
) => {
  const res = {
    isValid: true,
    errors: [],
  };

  password = password.trim();
  confirmPassword = confirmPassword?.trim();

  if (!password) {
    return {
      isValid: false,
      errors: [
        {
          field: 'password',
          description: '109',
        },
      ],
    };
  }

  if (typeof confirmPassword === 'string' && password !== confirmPassword) {
    res.isValid = false;
    res.errors.push(
      {
        field: 'password',
        description: '108',
      },
      {
        field: 'confirm-password',
        description: '',
      },
    );
  }

  const {
    isValid: isMinLengthValid,
    errors: minCharsLengthErrors,
  } = stringHas8CharactersLong(password);

  if (!isMinLengthValid) {
    res.isValid = false;
    res.errors.push(...minCharsLengthErrors);
  }

  const {
    isValid: isMaxLengthValid,
    errors: maxCharsLengthErrors,
  } = stringHas100CharactersLong(password);

  if (!isMaxLengthValid) {
    res.isValid = false;
    res.errors.push(...maxCharsLengthErrors);
  }

  const {
    isValid: isUppercaseValid,
    errors: uppercaseErrors,
  } = stringHas1Uppercase(password);

  if (!isUppercaseValid) {
    res.isValid = false;
    res.errors.push(...uppercaseErrors);
  }

  const {
    isValid: isLatinValid,
    errors: latinErrors,
  } = checkIfPassIsLatin(password);

  if (!isLatinValid) {
    res.isValid = false;
    res.errors.push(...latinErrors);
  }

  return res;
};

export const validateGdprUserData: TValidate<TGdprUserDataField> = (gdprUserData) => {
  if (!gdprUserData) {
    return {
      isValid: false,
      errors: [
        {
          field: 'gdpr',
          description: '112',
        },
      ],
    };
  }

  return {
    isValid: true,
    errors: [],
  };
};

export const removeDuplicatesFromLeaderboard = (data: any): LeaderboardRecord[] => {
  const filteredData = [];

  data.forEach((row: any) => {
    // no level matching because it may differ because of out of sync
    const scoreDuplicate = filteredData.find((filteredRow) => filteredRow.score === row.score);
    const nameDuplicate = filteredData.find((filteredRow) => filteredRow.name === row.name);

    if (!scoreDuplicate && !nameDuplicate) {
      filteredData.push(row);
      return;
    }

    const avatarDuplicate = filteredData.find((filteredRow) => filteredRow.avatar === row.avatar);

    if (!avatarDuplicate) {
      filteredData.push(row);
      return;
    }

    const isCurrentUserDuplicate = filteredData.find(
      (filteredRow) => filteredRow.isCurrentUser === row.isCurrentUser,
    );

    if (!isCurrentUserDuplicate) {
      filteredData.push(row);
      return;
    }
  });
  return filteredData;
};

export const setBodyOverflowHidden = () => {
  document.body.classList.add(OverflowHiddenBodyClassname);
};

export const setBodyOverflowAuto = () => {
  document.body.classList.remove(OverflowHiddenBodyClassname);
};

export const setBodyHeightOneHundred = () => {
  document.body.classList.add(HeightOneHundredBodyClassname);
};

export const setBodyHeightAuto = () => {
  document.body.classList.remove(HeightOneHundredBodyClassname);
};

type TPostMessageData = {
  type: string;
  payload?: any;
};

export const sendPostMessage = (data: TPostMessageData) => {
  parent.postMessage({ ...data }, '*');
};

export const checkIfSlugInList = (slug: string, list: string[]): boolean => list.includes(slug);
