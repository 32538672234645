import classNames from 'classnames';
import React from 'react';
import { DeviceDetector } from '../../services/DeviceDetector';
import styles from './ShopBanner.css';
export const ShopBanner = (props: { addBottomMargin?: boolean }) => (
    <img
        className={classNames(styles.imageBanner, {
            [styles.mobile]: !DeviceDetector.isDesktop(),
            [styles.addBottomMargin]: props.addBottomMargin,
        })}
        src={'images/ShopBanner.png'}
        alt="Shop banner"
    />
);
