import { LOCATION_CHANGE, push } from 'connected-react-router';
import i18n from 'i18next';
import { createAction, createReducer } from 'redux-act';
import { debounce } from '../../../utils';
import { ActionTypes } from '../../models/Analytics';
import { GameState, KeysEnum } from '../../models/Enums';
import { HeaderMenu } from '../../models/HeaderMenu';
import { HeaderSearchResult } from '../../models/HeaderSearchResult';
import { LoginCaller, LoginState } from '../../models/Login';
import { AnalyticsGeneral } from '../../services/Analytics/AnalyticsGeneral';
import { SearchService } from '../../services/Search';
import { SSOService } from '../../services/SSOService';
import { UrlService } from '../../services/UrlService';
import { gamesByLangSelector } from './games';
import { setLoginDialog } from './loginDialog';
import { logoutUser } from './user';
import { CookieStorageService } from '../../services/CookieStorage';
import { ELoginSteps, setLoginModal, setShopOpen } from './modal';

// External Action Types
export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_ITEM_CLICK = 'SEARCH_ITEM_CLICK';
export const NAVIGATION_REQUEST = 'NAVIGATION_REQUEST';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const CHANGE_LANGUAGE_REQUEST = 'CHANGE_LANGUAGE_REQUEST';
export const PROFILE_REQUEST = 'PROFILE_REQUEST';
export const OPEN_SHOP_MODAL_REQUEST = 'OPEN_SHOP_MODAL_REQUEST';

export const MapActionTypesToThunks = {
    [SEARCH_REQUEST]: searchGamesThunk,
    [SEARCH_ITEM_CLICK]: searchGameClickThunk,
    [NAVIGATION_REQUEST]: navigateThunk,
    [LOGIN_REQUEST]: headerLoginThunk,
    [LOGOUT_REQUEST]: headerLogoutThunk,
    [CHANGE_LANGUAGE_REQUEST]: changeLanguageThunk,
    [PROFILE_REQUEST]: goToProfileThunk,
    [OPEN_SHOP_MODAL_REQUEST]: openShopModalThunk,
};

interface HeaderTheme {
    logo: string;
    background: string;
    color: string;
    disableLogin: boolean;
    balance?: string;
}

export interface HeaderState {
    currentLocation?: string;
    menu?: HeaderMenu;
    searchResults?: HeaderSearchResult[];
    parentSiteUrl?: string;
    searchTerm?: string;
    theme?: HeaderTheme;
    userCountry?: string;
}

export const setHeaderMenu = createAction<HeaderMenu>('set menu');
export const setHeaderLocation = createAction<string>('set current location');
export const setHeaderSearchResults = createAction<HeaderSearchResult[]>('set search results');
export const setHeaderTheme = createAction<HeaderTheme>('set theme');
export const setHeaderParentSiteUrl = createAction<string>('set parent site url');
export const setHeaderSearchTerm = createAction<string>('set search term');
export const setHeaderUserCountry = createAction<string>('set user country');
export const setHeaderGemsAmount = createAction<string>('set header gems amount');
const reducer = createReducer<HeaderState>({}, { searchTerm: '' });

reducer.on(LOCATION_CHANGE, (_state, payload: any) => ({ ..._state, currentLocation: payload.location.pathname }));
reducer.on(setHeaderMenu, (_state, payload = []) => ({ ..._state, menu: payload }));
reducer.on(setHeaderSearchResults, (_state, payload = []) => ({ ..._state, searchResults: payload }));
reducer.on(setHeaderTheme, (_state, payload = null) => ({ ..._state, theme: payload }));
reducer.on(setHeaderParentSiteUrl, (_state, payload = null) => ({ ..._state, parentSiteUrl: payload }));
reducer.on(setHeaderSearchTerm, (_state, payload: string) => ({ ..._state, searchTerm: payload }));
reducer.on(setHeaderUserCountry, (_state, payload: string) => ({ ..._state, userCountry: payload }));
reducer.on(setHeaderGemsAmount, (_state, payload: string) => ({
    ..._state,
    theme: { ..._state.theme, balance: payload },
}));

export default reducer;

const debounceSearchAnalytics = debounce((searchTerm) => {
    // Tracking not sanitized term
    if (searchTerm) {
        AnalyticsGeneral.searchingAnalytics(ActionTypes.QUERY, searchTerm);
    }
}, 500);

/* Thunks */
function searchGamesThunk(searchTerm: string | any) {
    return function (dispatch, getState) {
        debounceSearchAnalytics(searchTerm);

        dispatch(setHeaderSearchTerm(searchTerm));

        searchTerm = SearchService.prepareSearchParam(searchTerm);
        const availableGames = gamesByLangSelector(getState());

        SearchService.quickSearch(availableGames, searchTerm).then((results) => {
            dispatch(setHeaderSearchResults(results));
        });
    };
}

function searchGameClickThunk({ slug, url }: { slug: string; url: string }) {
    return function (dispatch, getState) {
        AnalyticsGeneral.searchingAnalytics(ActionTypes.CLICK, slug);
        dispatch(setHeaderSearchResults([]));
        dispatch(setHeaderSearchTerm(''));
        dispatch(goToUrl(url));
    };
}

function navigateThunk({ url, category, event }: { url: string; category: string; event: object }) {
    return function (dispatch, getState) {
        AnalyticsGeneral.topNavCategories(ActionTypes.CLICK, category);
        dispatch(goToUrl(url));
    };
}

function headerLoginThunk() {
    return function (dispatch, getState) {
        const ssoUrl = SSOService.getLoginPageUrl(getState().config, window.location.href);

        if (ssoUrl) {
            CookieStorageService.set(KeysEnum.arkStateReturnUrl, window.location.href);
            window.location.href = ssoUrl;
        } else {
            // dispatch(setLoginDialog({ show: true, state: LoginState.Login, caller: LoginCaller.TopNav }));
            dispatch(setLoginModal({ isOpen: true, step: ELoginSteps.LOGIN, caller: LoginCaller.TopNav }));
        }
    };
}

function headerLogoutThunk() {
    return function (dispatch, getState) {
        dispatch(logoutUser());
    };
}

function changeLanguageThunk(lang: string) {
    return function (dispatch, getState) {
        window.location.replace(`/${lang}`);
    };
}

function goToProfileThunk() {
    return function (dispatch, getState) {
        dispatch(goToUrl(UrlService.getWindowBaseHref() + `/${i18n.t('ROUTES.PROFILE')}`));
    };
}

function openShopModalThunk() {
    return function (dispatch, getState) {
        dispatch(setShopOpen(true));
    };
}

function goToUrl(url: string) {
    return function (dispatch, getState) {
        if (url) {
            url = (url as any).href || url;
            const gameState = getState().gameState;
            const isGame = gameState && gameState !== GameState.AD_BLOCKER;

            if (isGame) {
                window.location.replace(url);
            } else {
                dispatch(push(url));
            }
        }
    };
}
