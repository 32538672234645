import classNames from 'classnames';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { AppState } from '../../store/types';
import styles from './PageBottomDescription.css';
import { frenchTextFix, IEventCategory } from '../../services/EventCategoryService';

type PageBottomDescriptionProps =
    {
        category?: string;
        clientName: string;
        eventCategoryData?: IEventCategory;
        lang: string;
    }
    & WithTranslation;

const PageBottomDescriptionBase = React.memo(({
                                                  category,
                                                  clientName,
                                                  t,
                                                  eventCategoryData,
                                                  lang,
                                              }: PageBottomDescriptionProps) => {
    const categoryText = category && category.toLowerCase();
    let eventCategoryNameLocal = '';
    let eventCategoryLocalisation = '';
    const eventCategoryDataUsed = eventCategoryData || { common: [] };
    const isMetroCoUk = useSelector(({ config: { theme } }) => theme.client === 'metro-co-uk');

    eventCategoryDataUsed.common.forEach(eventData => {
        const eventCategoryNames = [];

        eventCategoryNames.push(eventData.eventName.toLowerCase());
        Object.values(eventData.eventNameLocales)
            .forEach(value => eventCategoryNames.push(value.toLowerCase()));

        if (categoryText && eventCategoryNames.includes(categoryText)) {
            eventCategoryLocalisation = eventData.eventDescriptionLocales[lang] || eventData.eventDescription || '';
            eventCategoryNameLocal = eventData.eventNameLocales[lang] || eventData.eventName || '';
        }
    });

    const title = eventCategoryNameLocal ? eventCategoryNameLocal : categoryText
        ? t('CATEGORY_BOTTOM_DESCRIPTION_TITLE', { category: categoryText })
        : t('HOME_BOTTOM_DESCRIPTION_TITLE', { clientName });
    const text = eventCategoryLocalisation ? eventCategoryLocalisation : categoryText
        ?
        t(
            'CATEGORY_BOTTOM_DESCRIPTION_TEXT',
            {
                category: categoryText,
                clientName,
            },
        )
        :
        t('HOME_BOTTOM_DESCRIPTION_TEXT', { clientName });

    return (
        <div className={styles.container}>
            <h2 className={classNames(styles.title, { [styles.titleCapitalized]: !isMetroCoUk })}>
                {frenchTextFix(title)}
            </h2>
            <p>{text}</p>
        </div>
    );
});
const PageBottomDescriptionTranslated = withTranslation()(PageBottomDescriptionBase);

export const PageBottomDescription = connect((state: AppState) => ({
    clientName: state.config.theme.name,
    eventCategoryData: state.config.eventCategoryConfig,
    lang: state.currentLang,
}))(PageBottomDescriptionTranslated);
