import classNames from 'classnames';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DeviceDetector } from '../../services/DeviceDetector';
import { setShopOpen } from '../../store/ducks/modal';
import { BuyButton } from '../Buttons/BuyButton';
import { TextButton } from '../Buttons/TextButton';
import styles from './ShopErrorScreen.css';
type TShopErrorScreenProps = {
    onTryAgainClick: () => void;
};

const ShopErrorScreenBase = ({ t, onTryAgainClick }: WithTranslation & TShopErrorScreenProps) => {
    const isDesktop = DeviceDetector.isDesktop();
    const dispatch = useDispatch();
    const onGoBackClick = () => {
        dispatch(setShopOpen(false));
    };

    return (
        <article className={styles.shopErrorWrapper}>
            <img
                className={classNames(styles.shopErrorImage, { [styles.mobile]: !isDesktop })}
                src="/images/GemsErrorScreen.png"
                alt="Gems chest"
            />
            <h4 className={classNames(styles.shopErrorTitle, { [styles.mobile]: !isDesktop })}>
                {t('GEMS.ERROR_TITLE')}
            </h4>
            <BuyButton
                className={styles.shopErrorTryAgainButton}
                onClick={onTryAgainClick}
                price={t('GEMS.ERROR_BUTTON')}
            />
            <TextButton
                className={styles.shopErrorBackButton}
                isEagle
                onClick={onGoBackClick}
                text={t('GEMS.ERROR_BUTTON_BACK')}
            />
        </article>
    );
};

export const ShopErrorScreen = withTranslation()(ShopErrorScreenBase);
